import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import './cookie-consent-banner.scss';


const CookieConsentBanner: React.FC = () => {

    const [cookieConsent, setCookieConsent] = useState<string | undefined>(undefined);
    
    useEffect(() => {
        const consent = Cookies.get("cookieConsent");

        if (consent === "denied") {
            setCookieConsent("denied");
        } else if (consent === "accepted") {
            setCookieConsent("accepted");
        } else {
            setCookieConsent(undefined);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set("cookieConsent", "accepted");
        setCookieConsent("accepted");

        console.log("User accepted cookies");
        console.log("User-Agent:", window.navigator.userAgent);
        console.log("User Language:", window.navigator.language);

        // Log user information from cookies
        console.log("Session ID:", Cookies.get("sessionId"));
    }

    const handleDeny = () => {
        Cookies.set("cookieConsent", "denied");
        setCookieConsent("denied");
    }

    if (cookieConsent === "accepted") {
        return null;
    } else if (cookieConsent === "denied") {
        return null;
    }

    return (
        <div className="cookie">
            <p className="cookie__title">This website uses cookies to enhance your experience.</p>
            <div className="cookie__button">
                <button 
                    className="cookie__button-item accept-button"
                    onClick={handleAccept}
                >
                    Accept
                </button>
                <button 
                    className="cookie__button-item deny-button"
                    onClick={handleDeny}
                >
                    Deny
                </button>
            </div>
        </div>
    )
}

export default CookieConsentBanner;