import './App.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Homepage from './components/homepage/homepage';
import React from 'react';
import CookieConsentBanner from './components/cookie/cookie-consent-banner';
import loadAnalytics from './load-analytics';


function App() {

  return (
    <div className="App">
      <Homepage />
      <CookieConsentBanner />
    </div>
  );
}

loadAnalytics();

export default App;