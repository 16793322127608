/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useTranslation } from "react-i18next";
import slugid from 'slugid';

import { useHooks } from "../../hooks";
import BootstrapDialog from "../modal-window-instructions/modal-window-instructions";
import generateClientIdGa from "../../generate-client-id-ga";
import './file-upload.scss';
import './cropper.css';

const API = 'https://yopix.com.ua/api/';
const pixelateApiUrl: string | URL | Request = `${API}v1/image/pixelation`;
const downloadApiUrl: string | URL | Request = `${API}v1/image/download`;

const slug = slugid.nice();
let clientId = generateClientIdGa();

enum HttpRequest {
    Get = 'GET',
    Post = 'POST'
}

interface AspectRatios extends Array<AspectRatio>{};

interface AspectRatio {
    value: number;
    sizeCode: string;
    text: string;
};

const aspectRatios: AspectRatios = [
    {
        value: 2/2,
        sizeCode: '2x2',
        text: 'Pix 2x2'
    },
    {
        value: 2/3,
        sizeCode: '2x3',
        text: 'Pix 2x3'
    },
    {
        value: 3/2,
        sizeCode: '3x2',
        text: 'Pix 3x2'
    },
    {
        value: 2/4,
        sizeCode: '2x4',
        text: 'Pix 2x4'
    },
    {
        value: 4/2,
        sizeCode: '4x2',
        text: 'Pix 4x2'
    },
    {
        value: 3/3,
        sizeCode: '3x3',
        text: 'Pix 3x3'
    },
    {
        value: 3/4,
        sizeCode: '3x4',
        text: 'Pix 3x4'
    },
    {
        value: 4/3,
        sizeCode: '4x3',
        text: 'Pix 4x3'
    },
    {
        value: 4/4,
        sizeCode: '4x4',
        text: 'Pix 4x4'
    }
];


const FileUpload: React.FC = () => {
    const { t } = useTranslation();

    const {
        handleFileSelect,
        imageContainerRef,
        inputFileRef,
        resetSelection,
        objectURL,
        selectedFileName
    } = useHooks();

    const [aspect, setAspect] = useState(aspectRatios[1]);
    const [arrOfImageNames, setArrOfImageNames] = useState([]);
    const [pixImage, setPixImage] = useState();

    const cropperRef = useRef<ReactCropperElement>(null);

    let croppedImgCanvas: any;
    const pixelPreviewImage = document.querySelector('.pixel-preview-img') as HTMLImageElement;
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 1
    };


    const onAspectChange = (e: { target: { value: any; }; }) => {
        const value = e.target.value;
        const ratio = aspectRatios.find((ratio) => ratio.value == value);
        if (ratio === undefined) {
            throw new TypeError('The value was promised to always be there!');
        }
        setAspect(ratio);
        cropperRef.current?.cropper.setAspectRatio(ratio.value);
    };

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            croppedImgCanvas = cropperRef.current?.cropper.getCroppedCanvas();
        }

        return croppedImgCanvas;
    };

    const handleUpload = async () => {
        let getImage = getCropData();
        let formData = new FormData();

        let imageBlob: Blob = await new Promise(resolve => getImage.toBlob(resolve, 'image/jpeg'));
        
        formData.append("image", imageBlob, selectedFileName);
        formData.append('sizeCode', aspect.sizeCode);

        let response = await fetch(pixelateApiUrl, {
            method: HttpRequest.Post,
            body: formData,
            headers: {
                'X-Request-Id' : slug,
                'X-Client-Id' : clientId
            }
        });

        let result = await response.clone().json();
        return result.imageList;
    }

    const setImageNames = (names: React.SetStateAction<never[]>) => {
        setArrOfImageNames(names);
    }

    const getPixImages = () => {
        handleUpload().then(arr => setImageNames(arr.map((item: any) => item)));
    }

    const displayPixImage = (e: { currentTarget: { getAttribute: (arg0: string) => any; }; }) => {
        let title = e.currentTarget.getAttribute('title');
        let value = e.currentTarget.getAttribute('src');
        setPixImage(value);

        pixelPreviewImage.src = value;
        pixelPreviewImage.alt = title;
        pixelPreviewImage.title = title;
    }

    return (
        <div className="action-button">
            <form>
                <label htmlFor="fileUpload">{t('labels.chooseFileLabel')}</label>
                <input
                    id="fileUpload"
                    accept="image/*"
                    type="file"
                    name="fileUpload"
                    className="hidden-upload"
                    ref={inputFileRef}
                    onChange={handleFileSelect}
                    data-testid="file-input"
                    />
            </form>

            <div className="piximage-size">
                <label htmlFor="piximage-size">{t('labels.choosePixelImageSizeLabel')}</label>
                <select name="piximage-size" className="aspect-ratio-select" id="piximage-size" onChange={onAspectChange}>
                    {aspectRatios.map((ratio) => (
                    <option
                        key={ratio.text}
                        value={ratio.value}
                        selected={ratio.value === aspect.value}
                    >
                        {ratio.text}
                    </option>
                    ))}
                </select>
            </div>

            <div className="image-workspace">
                <div className="image-workspace-box">
                    <div 
                        className="image-workspace-container user-image"
                        ref={imageContainerRef}
                    >
                        <Cropper
                            style={{ height: 500 }}
                            className="cropper"
                            dragMode={'move'}
                            aspectRatio={aspect.value}
                            src={objectURL}
                            ref={cropperRef}
                            viewMode={3}
                            autoCropArea={0.75}
                            modal={false}
                            background={false}
                            center={false}
                            toggleDragModeOnDblclick={false}
                        />
                    </div>
                </div>
                <div className="image-workspace-box">
                    <div className="image-workspace-container pixel-preview">
                        <img 
                            src={`${downloadApiUrl}/${arrOfImageNames[0]}`}
                            className="pixel-preview-img"
                            title={arrOfImageNames[0]}
                            alt={arrOfImageNames[0]}
                        />
                    </div>
                </div>
            </div>

            <label htmlFor="upload-button">{t('labels.uploadImageSizeLabel')}</label>
            <button id="upload-button" className="upload" onClick={getPixImages}>
                {t('buttons.uploadButton')}
            </button>

            <div>
                <button type='submit' className="clear-img-btn" onClick={resetSelection}>
                    {t('buttons.clearImageButton')}
                </button>
            </div>

            <BootstrapDialog />
            
            <div className="slider">
                <h2> Slider</h2>
                <Slider {...settings}>
                    {
                        arrOfImageNames.map(name => (
                            <div 
                                className="slider__container" 
                                key={slug} // in the future slug uuid should be replaced with keys/IDs from a database
                            >
                                <img 
                                    className="slider__img"
                                    title={name}
                                    alt={name}
                                    src={`${downloadApiUrl}/${name}`}
                                    onClick={displayPixImage}
                                />
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}

export default FileUpload;
