import * as React from 'react';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';

import LanguagePicker from '../../components/sharedComponents/languagePicker/language-picker';

import './header.scss';
import sprite from '../../icons/sprite.svg';
import logo from '../../icons/logo.png';

const menuCollapsed = (
    <svg className='header__nav-btn-toggle-icon close-icon' width="16" height="17" focusable="false">
        <use href={sprite + "#close"} />
    </svg>
);

const menuOpen = (
    <svg className='header__nav-btn-toggle-icon hamburger-icon' width="34" height="32" focusable="false">
        <use href={sprite + "#hamburger"} />
    </svg>
);

const Header = () => {
    
    const { t } = useTranslation();

    const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const toggleMenu = () => {
        setIsMenuCollapsed(!isMenuCollapsed);
    }

    const openAccountMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const closeAccountMenu = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <header className='header'>
            <div className='header__inner'>
                <div className='header__logo'>
                    <Link to="/" className='header__logo-link'>
                        <img className='header__logo-img' src={logo} alt="" />
                    </Link>
                </div>
                <nav className='header__nav'>
                    <ul className={isMenuCollapsed ? 'header__nav-list active' : 'header__nav-list'}>
                        <li className='header__nav-list-item'>
                            <NavLink
                                end
                                to="/about-us"
                                className='header__nav-list-link'
                                style={({isActive}) => ({color: isActive ? '#a62a13' : 'inherit'})}
                            >
                                {t('navigation.links.aboutUsNavigationLink')}
                            </NavLink>
                        </li>
                        <li className='header__nav-list-item'>
                            <NavLink
                                end
                                to="/contacts"
                                className='header__nav-list-link'
                                style={({isActive}) => ({color: isActive ? '#a62a13' : 'inherit'})}
                            >
                                {t('navigation.links.contactsNavigationLink')}</NavLink>
                        </li>
                        <li className='header__nav-list-item'>
                            <NavLink
                                end
                                to="/catalog"
                                className='header__nav-list-link'
                                style={({isActive}) => ({color: isActive ? '#a62a13' : 'inherit'})}
                            >
                                {t('navigation.links.catalogNavigationLink')}
                            </NavLink>
                        </li>
                    </ul>
                    <ul className='header__nav-actions'>
                        <li className='header__nav-actions-item'>
                            <button className='header__nav-actions-account-button' aria-describedby={id} onClick={openAccountMenu}>
                                <svg className='header__nav-actions-icon account-icon' width="38" height="35" focusable="false">
                                    <use href={sprite + "#account"} />
                                </svg>
                            </button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={closeAccountMenu}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                                }}
                            >
                                <div className='header__nav-actions-account-dropdown'>
                                    <div className='header__nav-actions-account-dropdown-icon'> {/*  className='header__nav-actions-account-dropdown' */}
                                        <svg className='account-icon' width="38" height="35" focusable="false">
                                            <use href={sprite + "#account"} />
                                        </svg>
                                    </div>
                                    <ul className='header__nav-actions-account-dropdown-list'>
                                        <li className='header__nav-actions-account-dropdown-item'>
                                            <a className='header__nav-actions-account-dropdown-link' href="">
                                                {t('navigation.links.authorizationNavigationLink')}
                                            </a>
                                        </li>
                                        <li className='header__nav-actions-account-dropdown-item'>
                                            <a className='header__nav-actions-account-dropdown-link' href="">
                                                {t('navigation.links.registrationNavigationLink')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Popover>
                        </li>
                        <li className='header__nav-actions-item'>
                            <a className='header__nav-actions-link' href="">
                                <svg className='header__nav-actions-icon cart-icon' width="34" height="31" focusable="false">
                                    <use href={sprite + "#cart"} />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </nav>
                <LanguagePicker />
                <div className='header__nav-toggle'>
                    <button className='header__nav-btn-toggle' onClick={toggleMenu}>
                        {isMenuCollapsed ? menuCollapsed : menuOpen}
                    </button>
                </div>
            </div>
        </header>
    )
}

export default Header;
