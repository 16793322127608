import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './homepageBanner.scss';

const HomepageBanner = () => {

    const { t } = useTranslation();

    return (
        <section className='homepage-banner'>
            <div className='homepage-banner__content-wrap'>
                <div className='homepage-banner__content-item'>
                    <div className='homepage-banner__content-block'>
                        <h1 className='homepage-banner__content-title'>{t('content.headings.homepageBannerContentHeading')}</h1>
                    </div>
                    <div className='homepage-banner__buttons-container'>
                        <div className='homepage-banner__button-item btn'>
                            <Link to={'./catalog'} className='homepage-banner__button-link btn-primary'>
                                {t('buttons.orderButton')}
                            </Link>
                        </div>
                        <div className='homepage-banner__button-item btn'>
                            <Link to={'./upload'} className='homepage-banner__button-link btn-secondary'>
                                {t('buttons.generateButton')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomepageBanner;
