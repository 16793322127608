import { useTranslation } from 'react-i18next';
import Accordion from '../../services/accordion/accordion';
import './faq-page.scss';

const FaqPage: React.FC = () => {

    const { t } = useTranslation();

    const accordionItems = [
        {
            title: t('faq.questions.question1_FaqQuestion'),
            content: t('faq.answers.answer1_FaqQuestion')
        },
        {
            title: t('faq.questions.question2_FaqQuestion'),
            content: t('faq.answers.answer2_FaqQuestion')
        },
        {
            title: t('faq.questions.question3_FaqQuestion'),
            content: t('faq.answers.answer3_FaqQuestion')
        },
        {
            title: t('faq.questions.question4_FaqQuestion'),
            content: t('faq.answers.answer4_FaqQuestion')
        },
        {
            title: t('faq.questions.question5_FaqQuestion'),
            content: t('faq.answers.answer5_FaqQuestion')
        },
        {
            title: t('faq.questions.question6_FaqQuestion'),
            content: t('faq.answers.answer6_FaqQuestion')
        },
        {
            title: t('faq.questions.question7_FaqQuestion'),
            content: t('faq.answers.answer7_FaqQuestion')
        },
        {
            title: t('faq.questions.question8_FaqQuestion'),
            content: t('faq.answers.answer8_FaqQuestion')
        },
        {
            title: t('faq.questions.question9_FaqQuestion'),
            content: t('faq.answers.answer9_FaqQuestion')
        }
    ];

    return (
        <section className='faq-page' id='faq'>
            <div className='faq-page__inner'>
                <h1 className='faq-page__title'>{t('faq.title')}</h1>
                <div className='faq-page__container'>
                    <Accordion items={accordionItems} />
                </div>
            </div>
        </section>
    )
}

export default FaqPage;
