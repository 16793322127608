import React from "react";
import { useTranslation } from 'react-i18next';
import './language-picker.scss';

const LanguagePicker = () => {
    const {i18n} = useTranslation();

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
    }

    return (
        <div className="language-picker">
            <div className='language-picker__item'>
                <input className='language-picker__input' type="radio" id='language-selector-ua' name='language-selector' checked />
                <label className='language-picker__label' htmlFor="language-selector-ua" onClick={() => handleLanguageChange('ua')}>UA</label>
            </div>
            <div className='language-picker__item'>
                <input className='language-picker__input' type="radio" id='language-selector-en' name='language-selector' />
                <label className='language-picker__label' htmlFor="language-selector-en" onClick={() => handleLanguageChange('en')}>EN</label>
            </div>
        </div>
    )
}

export default LanguagePicker;