import { useState, useRef, ChangeEventHandler } from 'react';


export const useHooks = () => {
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [objectURL, setObjectURL] = useState("");


    const resetSelection = () => {
        setObjectURL("");
        let cropperContainer = document.querySelector('.cropper-container') as HTMLElement;
        if (cropperContainer) {
            cropperContainer.style.display = 'none';
        }
    };
    
    const handleFiles = (files: FileList | null) => {
        resetSelection();
        if (!files || files?.length === 0) return;
        const file = files[0];

        if (!file.type.includes('image/')) {
            if (inputFileRef.current) {
                inputFileRef.current.value = '';
            }
            return;
        }

        const imageContainer = imageContainerRef.current;
        if (!imageContainer) return;
    
        const objectURL = window.URL.createObjectURL(file);
        setObjectURL(objectURL);
    };

    const [selectedFileName, setSelectedFileName] = useState('');

    const handleFileSelect: ChangeEventHandler<HTMLInputElement> = (event) => {
        const files = event.currentTarget.files;
        handleFiles(files);
        setSelectedFileName(files![0].name);
    }

    return {
        handleFileSelect,
        imageContainerRef,
        inputFileRef,
        resetSelection,
        objectURL,
        selectedFileName
    }
};
