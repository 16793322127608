import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './footer.scss';
import sprite from '../../icons/sprite.svg';
import logo from '../../icons/logo.png';

const Footer = () => {

    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer__inner">
                <div className="footer__content">
                    <div className='footer__logo'>
                        <Link to="/" className='footer__logo-link'>
                            <img className='footer__logo-img' src={logo} alt="" />
                        </Link>
                    </div>
                    <div className='footer__worktime'>
                        <div className='footer__worktime-headline'>
                            <p className='footer__worktime-headline-title'>{t('content.headings.workingTimesContentHeading')}</p>
                        </div>
                        <div className='footer__worktime-description'>
                            <p className='footer__worktime-description-text'>{t('content.text.startWorkingTimeContentText')}</p>
                            <p className='footer__worktime-description-text'>{t('content.text.ensWorkingTimeContentText')}</p>
                        </div>
                    </div>
                    <div className='footer__nav'>
                        <ul className='footer__nav-list nav-list-1'>
                            <li className='footer__nav-item'>
                                <a className='footer__nav-link' href="">{t('navigation.links.loginNavigationLink')}</a>
                            </li>
                            <li className='footer__nav-item'>
                                <a className='footer__nav-link' href="">{t('navigation.links.aboutUsNavigationLink')}</a>
                            </li>
                            <li className='footer__nav-item'>
                                <a className='footer__nav-link' href="">{t('navigation.links.contactsNavigationLink')}</a>
                            </li>
                            <li className='footer__nav-item'>
                                <Link to={{ 
                                    pathname: '/',
                                    hash: 'faq'
                                 }} className='footer__nav-link'>
                                    {t('navigation.links.faqNavigationLink')}
                                </Link>
                            </li>
                        </ul>
                        <ul className='footer__nav-list nav-list-2'>
                            <li className='footer__nav-item'>
                                <Link to="/catalog" className='footer__nav-link'>
                                    {t('navigation.links.catalogNavigationLink')}
                                </Link>
                            </li>
                            <li className='footer__nav-item'>
                                <a className='footer__nav-link' href="">{t('navigation.links.howItWorksNavigationLink')}</a>
                            </li>
                            <li className='footer__nav-item'>
                                <a className='footer__nav-link' href="">{t('navigation.links.paymentAndDeliveryNavigationLink')}</a>
                            </li>
                        </ul>
                    </div>
                    <div className='footer__social'>
                        <ul className='footer__social-items'>
                            <li className='footer__social-item'>
                                <a className='footer__social-link' href="" title={t('footer.socialLinks.instagramFooterSocialLink')}>
                                    <svg className='footer__social-icon instagram-icon' focusable="false">
                                        <use href={sprite + "#instagram"} />
                                    </svg>
                                </a>
                            </li>
                            <li className='footer__social-item'>
                                <a className='footer__social-link' href="" title={t('footer.socialLinks.youtubeFooterSocialLink')}>
                                    <svg className='footer__social-icon youtube-icon' focusable="false">
                                        <use href={sprite + "#youtube"} />
                                    </svg>
                                </a>
                            </li>
                            <li className='footer__social-item'>
                                <a className='footer__social-link' href="" title={t('footer.socialLinks.telegramFooterSocialLink')}>
                                    <svg className='footer__social-icon telegram-icon' focusable="false">
                                        <use href={sprite + "#telegram"} />
                                    </svg>
                                </a>
                            </li>
                            <li className='footer__social-item'>
                                <a className='footer__social-link' href="" title={t('footer.socialLinks.facebookFooterSocialLink')}>
                                    <svg className='footer__social-icon facebook-icon' focusable="false">
                                        <use href={sprite + "#facebook"} />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="footer__content-bottom">
                    <ul className="footer__security">
                        <li className="footer__security-item">
                            <a className="footer__security-link" href="">
                                {t('footer.securityLinks.publicOfferFooterSecurityLink')}
                            </a>
                        </li>
                        <li className="footer__security-item">
                            <a className="footer__security-link" href="">
                                {t('footer.securityLinks.privacyPolicyFooterSecurityLink')}
                            </a>
                        </li>
                    </ul>
                    <p className="footer__copyright">{t('footer.copyright.publicOfferFooterCopyright')}</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;