import {HashRouter as Router, Routes, Route} from "react-router-dom";

import {Header, Footer, HomepageBanner, FileUpload, Catalog, FaqPage} from '../index';

import { ScrollHandler } from '../../services/scroll-handle/scrollHandle';

import './homepage.scss';

const Homepage = () => {

    return (
        <Router>
            <div className='homepage'>
                <Header />
                <Routes>
                    <Route path="/" element={
                        <>
                            <HomepageBanner />
                            <ScrollHandler children={<FaqPage />} />
                        </>
                    } />
                    <Route path="/upload" element={<FileUpload />} />
                    <Route path="/catalog" element={<Catalog />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    )
}

export default Homepage;
